import React, { useEffect, useState } from 'react';
import { HeadPage } from '../../componants';
import { useDisclosure } from '@mantine/hooks';
import { AddGoogleSheets, UpdatePasswordUser, UpdatePricesDelivery, UpdateSpreadsheet, UpdateUser } from '../../componants/Modal';
import Cookies from 'universal-cookie';
import { Box, Button, Grid, Group, Image, Stack, Text } from '@mantine/core';
import { useAccount } from '../../api';
import { IconKey, IconUser } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies(null, { path: '/' });

export function Settings () {
    const { t } = useTranslation();
    const [userData, setUserData] = useState<any>(null);
    const [openedAddModal, { open: openAddModal, close: closeAddModal }] = useDisclosure(false);
    const [openedEditUserModal, { open: openEditUserModal, close: closeEditUserModal }] = useDisclosure(false);
    const [openedEditPasswordUserModal, { open: openEditPasswordUserModal, close: closeEditPasswordUserModal }] = useDisclosure(false);
    const [openedEditSpreadsheetModal, { open: openEditSpreadsheetModal, close: closeEditSpreadsheetModal }] = useDisclosure(false);
    const [openedEditPricesModal, { open: openEditPricesModal, close: closeEditPricesModal }] = useDisclosure(false);
    const {loading, error, data: dataAccount, refetch} = useAccount({
        id: cookies.get('id')
    })

    useEffect(() => {
        if (dataAccount) {
            setUserData(dataAccount)
        }
    }, [dataAccount])
    
    return (
        <>
            <HeadPage
                page={t('settingsPage.name')}
                links={[
                    { title: t('settingsPage.links.link01'), href: '/dashboard' },
                    { title: t('settingsPage.links.link02'), href: '#' }
                ]}
                labelCreate=''
                labelExport=''
                onCreate={() => console.log()}
                onExport={() => console.log()}
                hiddenExport={true}
            />
            
            <Box bg={"#fff"} mt={20} style={{borderRadius: "5px", border: "1px solid #ced4da"}}>
                <Box p={10} py={15} style={{borderBottom: "1px solid #ced4da"}}>
                    <Text size='md'>{t('settingsPage.title01')}</Text>
                </Box>
                <Grid gutter={20} p={20} justify="flex-start" bg={"#eee"}>
                    <Grid.Col span={{base: 6, sm: 4}} >
                        <Box mih={140} bg={"#fff"} style={{borderRadius: "2px", cursor: 'pointer', boxShadow: "#eee 0px 0px 15px -5px", border: "1px solid #ced4da"}} p={20} onClick={openEditUserModal}>
                            <Stack justify='center' align='center' gap={5}>
                                <IconUser size={30} color="#16ba79" />
                                <Text mt={15} size='md' ta={'center'}>{t('settingsPage.card01.title')}</Text>
                                <Text size='xs' c={"gray.6"} ta={'center'}>{t('settingsPage.card01.description')}</Text>
                            </Stack>
                        </Box>
                    </Grid.Col>
                    
                    <Grid.Col span={{base: 6, sm: 4}} >
                        <Box mih={140} bg={"#fff"} style={{borderRadius: "2px", cursor: 'pointer', boxShadow: "#eee 0px 0px 15px -5px", border: "1px solid #ced4da"}} p={20} onClick={openEditPasswordUserModal}>
                            <Stack justify='center' align='center' gap={5}>
                                <IconKey size={30} color="#16ba79" />
                                <Text mt={15} size='md' ta={'center'}>{t('settingsPage.card02.title')}</Text>
                                <Text size='xs' c={"gray.6"} ta={'center'}>{t('settingsPage.card02.description')}</Text>
                            </Stack>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>

            <Box bg={"#fff"} mt={20} style={{borderRadius: "5px", border: "1px solid #ced4da"}}>
                <Box p={10} py={15} style={{borderBottom: "1px solid #ced4da"}}>
                    <Text size='md'>{t('settingsPage.title02')}</Text>
                </Box>
                <Grid gutter={20} p={20} justify="flex-start" bg={"#eee"}>
                    <Grid.Col span={{base: 6, sm: 4}} >
                        <Box mih={140} bg={"#fff"} style={{borderRadius: "2px", cursor: 'pointer', boxShadow: "#eee 0px 0px 15px -5px", border: "1px solid #ced4da"}} p={20} onClick={openEditPricesModal}>
                            <Stack justify='center' align='center' gap={5}>
                                <Image src={"/shipping-cost.png"} h={40} w={40} fit='cover'/>
                                <Text mt={15} size='md' ta={'center'}>{t('settingsPage.card03.title')}</Text>
                                <Text size='xs' c={"gray.6"} ta={'center'}>{t('settingsPage.card03.description')}</Text>
                            </Stack>
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={{base: 6, sm: 4}} >
                        <Box mih={140} bg={"#fff"} style={{borderRadius: "2px", cursor: 'pointer', boxShadow: "#eee 0px 0px 15px -5px", border: "1px solid #ced4da"}} p={20} onClick={openEditSpreadsheetModal}>
                            <Stack justify='center' align='center' gap={5}>
                                <Image src={"/google-sheets.png"} h={40} w={40} fit='cover'/>
                                <Text mt={15} size='md' ta={'center'}>{t('settingsPage.card04.title')}</Text>
                                <Text size='xs' c={"gray.6"} ta={'center'}>{t('settingsPage.card04.description')}</Text>
                            </Stack>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>

            <Box bg={"#fff"} mt={20} style={{borderRadius: "5px", border: "1px solid #ced4da"}}>
                <Box p={10} py={15} style={{borderBottom: "1px solid #ced4da"}}>
                    <Text size='md'>الدعم الفني</Text>
                </Box>
                <Grid gutter={20} p={20} justify="flex-start" bg={"#eee"}>
                    <Grid.Col span={{base: 12, md: 6, lg: 4}} >
                        <Box bg={"#fff"} p={20} style={{borderRadius: "5px", boxShadow: "#eee 0px 0px 15px -5px"}}>
                            <Group justify='space-between' mb={10}>
                                <Image src={"/icons8-télégramme-app-48.png"} h={40} w={40} />
                                <Button variant='default'>إنظمام</Button>
                            </Group>
                            <Text size='sm' fw={700} mb={5}>مجموعة تليجرام</Text>
                            <Text size='xs'>مجموعة تليجرام بها نخبة من الخبراء في مجال التجارة الإلكترونية مستعدين للإجابة على كل تسائلاتكم</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 6, lg: 4}} >
                        <Box bg={"#fff"} p={20} style={{borderRadius: "5px", boxShadow: "#eee 0px 0px 15px -5px"}}>
                            <Group justify='space-between' mb={10}>
                                <Image src={"/icons8-lecture-de-youtube-48.png"} h={40} miw={40} fit='contain'/>
                                <Button variant='default'>مشاهدة</Button>
                            </Group>
                            <Text size='sm' fw={700} mb={5}>قناة اليوتيوب</Text>
                            <Text size='xs'>مجموعة تليجرام بها نخبة من الخبراء في مجال التجارة الإلكترونية مستعدين للإجابة على كل تسائلاتكم</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 6, lg: 4}} >
                        <Box bg={"#fff"} p={20} style={{borderRadius: "5px", boxShadow: "#eee 0px 0px 15px -5px"}}>
                            <Group justify='space-between' mb={10}>
                                <Image src={"/icons8-whatsapp-48.png"} h={40} w={40} />
                                <Button variant='default'>إنظمام</Button>
                            </Group>
                            <Text size='sm' fw={700} mb={5}>مجموعة تليجرام</Text>
                            <Text size='xs'>مجموعة تليجرام بها نخبة من الخبراء في مجال التجارة الإلكترونية مستعدين للإجابة على كل تسائلاتكم</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>

            <UpdateUser title={t('settingsPage.updateUser')} opened={openedEditUserModal} data={userData} refetchData={refetch} onClose={closeEditUserModal} />
            <UpdatePasswordUser title={t('settingsPage.updatePasswordUser')} opened={openedEditPasswordUserModal} data={userData} onClose={closeEditPasswordUserModal} />
            <UpdateSpreadsheet title={t('settingsPage.updatePasswordUser')} opened={openedEditSpreadsheetModal} refetchData={refetch} data={userData} onClose={closeEditSpreadsheetModal} openAddModal={openAddModal} />
            <AddGoogleSheets opened={openedAddModal} refetchData={refetch} onClose={closeAddModal} />
            <UpdatePricesDelivery title={t('settingsPage.updatePricesDelivery')} opened={openedEditPricesModal} onClose={closeEditPricesModal} />
        </>
    );
}